import * as React from "react";
import { Button, Classes, Dialog, Intent, Radio, FormGroup, RadioGroup, Tooltip, Position } from "@blueprintjs/core";
import { handleStringChange } from "@blueprintjs/docs-theme";
import TabletSelectBox from "./../tablet-select-box";
import { ITablet } from "./../tablet-select-box/tablet";
import FragmentSelectBox from "./../fragment-select-box";
import { IFragment } from "./../fragment-select-box/fragment";
import {TargetProvider} from "../target-provider/target-provider";
import {Target} from "../target-provider/target";
import { Select } from "@material-ui/core";
import ModelSelectBox from "../model-select-box";
import { IModel, modelSelectProps } from "../model-select-box/model";

export interface IQueryDialogProps {
    onSuccess: ((task: string, targets?: Target[] | undefined, model?: IModel) => void),
    hideMenus: (() => void),
}

export interface IQueryDialogState {
    isOpen: boolean,
    option: string,
    disabled: boolean,
    currentTablet: ITablet | undefined,
    currentFragment: IFragment | undefined,
    selectedFragments: IFragment[] | undefined,
    selectedModel: IModel | undefined,
}

export class QueryDialog extends React.PureComponent<IQueryDialogProps, IQueryDialogState> {
    tooltipElement: React.RefObject<Tooltip> = React.createRef<Tooltip>();
    tabletSelectBoxElement: React.RefObject<TabletSelectBox> = React.createRef<TabletSelectBox>();
    targetProviderElement: React.RefObject<TargetProvider> = React.createRef<TargetProvider>();
    fragmentSelectBoxElement: React.RefObject<FragmentSelectBox> = React.createRef<FragmentSelectBox>();
    modelElement = React.createRef<ModelSelectBox>();

    constructor(props: IQueryDialogProps, state: IQueryDialogState) {
        super(props);
        this.state = {
            isOpen: false,
            disabled: false,
            option: 'annotate',
            currentTablet: undefined,
            currentFragment: undefined,
            selectedFragments: [],
            selectedModel: undefined,
        };

        this.handleAddFragment = this.handleAddFragment.bind(this);
    }

    handleOptionChange = handleStringChange(option => {
        this.setState({ option });
        this.modelElement.current.setState({type: option});
        this.modelElement.current.getModels();
    });

    handleTabletSelect(tablet: ITablet) {
        this.setState({ currentTablet: tablet });
        this.fragmentSelectBoxElement.current.getFragments(tablet.id);
    }

    handleFragmentSelect(fragment: IFragment) {
        this.setState({ currentFragment: fragment });
        //this.setState({ documentName: this.state.currentTablet?.name + ": " + fragment.name });
        //this.props.onItemSelect(fragment.id, fragment.name);
        //this.setState({ isOpen: false });
    }

    handleModelSelect(model: IModel) {
        console.log("Selected model: " + model.name);
        this.setState({selectedModel: model});
    }
    
    handleAddFragment() {
        //TODO: FIX by using the array from target-provider?
        /*
        let fragments : IFragment[] | undefined = this.state.selectedFragments;
        if (this.state.currentFragment !== undefined) {
            fragments?.push(this.state.currentFragment);
        }
        this.setState({selectedFragments: fragments});
        console.log(this.state.selectedFragments);
        */

        let target: Target = new Target();
        if ( this.state.currentFragment?.name !== undefined) {
            target.fragment = this.state.currentFragment?.name;
        }
        if ( this.state.currentTablet?.name !== undefined) {
            target.tablet = this.state.currentTablet?.name;
        }
        if ( this.state.currentFragment?.id !== undefined) {
            target.id = this.state.currentFragment?.id;
        }
        this.targetProviderElement.current?.appendClass(target);
    }

    public render() {
        return (
            <>            
                <Tooltip ref={this.tooltipElement} content="Issue tasks to the AI (a document has to be selected first)." position={Position.BOTTOM}>
                    <Button 
                        icon="log-in"
                        intent={Intent.PRIMARY}
                        onClick={this.handleOpen}
                        disabled={this.state.disabled}
                    >
                        Queue Query
                    </Button>
                </Tooltip>
                <Dialog
                    icon="log-in"
                    onClose={this.handleClose}
                    title="Query"                    
                    {...this.state}                    
                >
                    <div>
                        <form onSubmit={this.handleSubmit}>
                            <div className={Classes.DIALOG_BODY}>
                                <RadioGroup
                                    label="Task type:"
                                    onChange={this.handleOptionChange}
                                    selectedValue={this.state.option}>
                                    <Radio label="Annotate" value="annotate" />
                                    <Radio label="Query By Example (select a region using 'Select Rectangle first)'" value="querybyexample" />
                                    {/*<Radio label="Query By Expression" value="querybyexpression" />
                                    <Radio label="Classify" value="classify" />*/}
                                </RadioGroup>
                                {this.state.option === "querybyexample" && 
                                <div>
                                    <div>
                                    Specify target fragments (<b>select nothing if you want to search on the tablet that contains the query</b>):
                                    <TabletSelectBox
                                        ref={this.tabletSelectBoxElement}
                                        initialItem={this.state.currentTablet}
                                        onItemSelect={(tablet: ITablet) => this.handleTabletSelect(tablet)}
                                    />
                                    <FragmentSelectBox
                                        ref={this.fragmentSelectBoxElement}
                                        initialItem={this.state.currentFragment}
                                        onItemSelect={(fragment: IFragment) => this.handleFragmentSelect(fragment)}
                                    />
                                    <Button onClick={this.handleAddFragment}>
                                        Add fragment
                                    </Button>
                                </div>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: '1%',
                                    margin: '0%',
                                    width: '95%',
                                    height: '70%',
                                    border: 'none',
                                }}>
                                    <FormGroup
                                        style={{
                                            display: 'flex',
                                            width: '100%',
                                            border: 'none',
                                            margin: '0%'
                                        }}
                                        label={"Selected documents:"}
                                        labelFor={"sign_class_multi_select"}
                                    >
                                        <TargetProvider
                                            ref={this.targetProviderElement}
                                            id={"sign_class_multi_select"} 
                                        />
                                    </FormGroup>

                                    </div>
                                </div>
                                }
                                <ModelSelectBox
                                    ref={this.modelElement}
                                    onItemSelect={(model: IModel) => this.handleModelSelect(model) }
                                />
                            </div>
                            <div className={Classes.DIALOG_FOOTER}>
                                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                    <Tooltip content="Close the dialog.">
                                        <Button onClick={this.handleClose}>Cancel</Button>
                                    </Tooltip>
                                    <Button intent={Intent.PRIMARY} rightIcon="arrow-right" type="submit">Queue task</Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </Dialog>
            </>
        );
    }

    private handleOpen = () => {
        this.props.hideMenus();
        this.setState(
            { isOpen: true }
        );
    }
    
    private handleClose = () => {
        this.setState({ isOpen: false });
        this.targetProviderElement.current?.clear();
    }

    private handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.props.onSuccess(this.state.option, this.targetProviderElement.current?.getTargets(), this.state.selectedModel);
        this.handleClose();
    }
}

export default QueryDialog;