import { clientConfig } from './../../config.js';

import * as React from "react";
import { Button, Classes, Dialog, Intent, Tooltip, FormGroup, Position, InputGroup } from "@blueprintjs/core";

export interface ILoginDialogProps {
    onSuccess: (() => void),
    hideMenus: (() => void),
}

export interface ILoginDialogState {
    isOpen: boolean;
    username: string,
    password: string,
}

export class LoginDialog extends React.PureComponent<ILoginDialogProps, ILoginDialogState> {
    usernameElement = React.createRef<HTMLInputElement>();
    passwordElement = React.createRef<HTMLInputElement>();
    tooltipElement: React.RefObject<Tooltip> = React.createRef<Tooltip>();

    constructor(props: ILoginDialogProps, state: ILoginDialogState) {
        super(props);
        this.state = {
            isOpen: true,
            username: '',
            password: '',
        };
    }      

    public render() {
        return (
            <>
                <Dialog
                    //className={this.props.data.themeName}
                    icon="log-in"
                    title="Login"
                    isCloseButtonShown={false}
                    {...this.state}
                >
                    <form onSubmit={this.handleSubmit}>
                        <div className={Classes.DIALOG_BODY}>
                            <FormGroup
                                label="Username"
                                labelFor="text-input"
                                labelInfo="(required)">
                                <InputGroup id="username-input" autoFocus inputRef={this.usernameElement} placeholder="Username" name="username" onChange={this.handleChange} />
                            </FormGroup>
                            <FormGroup
                                label="Password"
                                labelFor="text-input"
                                labelInfo="(required)">
                                <InputGroup id="password-input" inputRef={this.passwordElement} placeholder="Password" name="password" type="password" onChange={this.handleChange} />
                            </FormGroup>
                        </div>
                        <div className={Classes.DIALOG_FOOTER}>
                            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                <Button intent={Intent.PRIMARY} rightIcon="arrow-right" type="submit">Login</Button>
                            </div>
                        </div>
                    </form>
                </Dialog>
            </>
        );
    }

    /**
     * Reset component states username and password.
     * This function is called every time a user tries to login (sign in)
     * @private
     */
    private reset() {
        // get all DOM input nodes and set their values to empty string
        Array.from(document.querySelectorAll("input")).forEach(
            input => (input.value = "")
        );
        // set both states username and password back to empty string ('')
        // TODO: is it necessary to call setState() twice? Or is it possible to call setState() once?
        this.setState({ username: '' });
        this.setState({ password: '' });
        // set the focus of input form to username text input
        this.usernameElement.current?.focus();
    };

    private handleOpen = () => {this.props.hideMenus(); this.setState({ isOpen: true })};
    private handleClose = () => this.setState({ isOpen: false });
    private handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.name === "username") {
            this.setState({ [event.target.name]: event.target.value });
        } else if (event.target.name === "password") {
            this.setState({ [event.target.name]: event.target.value });
        }
    }
    private handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        fetch(clientConfig.webApiProtocol + clientConfig.webApiUrl + ":" + clientConfig.webApiPort + "/api/authenticate", {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify({
                "username": this.state.username,
                "password": this.state.password
            })
        }).then(response => {
            return response.json();
        }).then(data => {
            if (data.success === true) {
                this.setState({ isOpen: false });
                localStorage.setItem('username', this.state.username);          //TODO: Ist das immer irgendwo vorhanden, sodass wir das nicht als Cookie setzen müssen?
                localStorage.setItem('token', data.token);
                localStorage.setItem('refreshToken', data.refreshToken);
                this.props.onSuccess();
            } else {
                // reset component states username and password
                // if the combination entered was wrong or does not exist
                this.reset();
            }
        }).catch(function (error) {
            console.log(error);
            console.log(error.message);
        });
    }
}

export default LoginDialog;