import React from "react";
import {MenuItem, Intent} from "@blueprintjs/core";
import {ItemPredicate, ItemRenderer} from "@blueprintjs/select";
import {SIGN_CLASSES, ISignClass} from './annotation-names'  

export const INTENTS = [Intent.NONE, Intent.PRIMARY, Intent.SUCCESS, Intent.DANGER, Intent.WARNING];

export const renderCreateSignOption = (
    query: string,
    active: boolean,
    handleClick: React.MouseEventHandler<HTMLElement>,
) => (
    <MenuItem
        icon="add"
        text={`Create "${query}"`}
        active={active}
        onClick={handleClick}
        shouldDismissPopover={false}
    />
)

export function createSign(sign: string): ISignClass {
    return {sign: sign};
}

export function areSignsEqual(signA: ISignClass, signB: ISignClass): boolean {
    return signA.sign.toLowerCase() === signB.sign.toLowerCase();
}

export function arrayContainsSign(signs: ISignClass[], signToFind: ISignClass): boolean {
    return signs.some((sign: ISignClass) => sign.sign === signToFind.sign)
}

export function maybeAddCreatedSignToArray(
    items: ISignClass[],
    createdItems: ISignClass[],
    sign: ISignClass
): { createdItems: ISignClass[], items: ISignClass[] } {
    // immediately invoke arrow function and evaluate boolean value if array (items) contains (sign)
    if (!arrayContainsSign(items, sign)) {
        createdItems.push(sign);
        items.push(sign);
    }
    return {createdItems, items};
}

export function maybeDeleteCreatedSignFromArray(
    items: ISignClass[],
    createdItems: ISignClass[],
    sign: ISignClass
): { createdItems: ISignClass[], items: ISignClass[] } {

    if (arrayContainsSign(createdItems, sign)) {
        createdItems = createdItems.filter(item => item !== sign);
        items = items.filter(item => item !== sign);
    }
    ;
    return {createdItems, items};
}


export function remove_special_characters(s: string) {
    var map = new Map([
        ['à', 'a'],
        ['á', 'a'],
        ['è', 'e'],
        ['é', 'e'],
        ['ì', 'i'],
        ['í', 'i'],
        ['ù', 'u'],
        ['ú', 'u'],
        ['û', 'u'],
        ['š', 's'],
        ['ḫ', 'h'],
        ['’', '\''],
        ['“', '\"'],
        ['”', '\"'],
    ]);

    var s_new: string = '';
    for (let i = 0; i < s.length; i++) {
        if (map.get(s[i]) === undefined) {
            s_new += s[i]
        } else {
            s_new += map.get(s[i]);
        }
    }
    return s_new
}

export const filterSign: ItemPredicate<ISignClass> = (query, item) => {
    const sign_clean_lower = remove_special_characters(item.sign.toLowerCase())
    if (sign_clean_lower.startsWith(query.toLowerCase())) {
        return true
    } else {
        return false
    }
} 

export function highlightText(text: string, query: string) {
    let lastIndex = 0;
    const words = query
        .split(/\s+/)
        .filter(word => word.length > 0)
        .map(escapeRegExpChars);
    if (words.length === 0) {
        return [text];
    }
    const regexp = new RegExp(words.join("|"), "gi");
    const tokens: React.ReactNode[] = [];
    while (true) {
        const match = regexp.exec(text);
        if (!match) {
            break;
        }
        const length = match[0].length;
        const before = text.slice(lastIndex, regexp.lastIndex - length);
        if (before.length > 0) {
            tokens.push(before);
        }
        lastIndex = regexp.lastIndex;
        tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
    }
    const rest = text.slice(lastIndex);
    if (rest.length > 0) {
        tokens.push(rest);
    }
    return tokens;
}

export function escapeRegExpChars(text: string) {
    // TODO: Still same lines?
    //return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
    return text.replace(/([.*+?^=!:${}()|[\]/\\])/g, "\\$1");
}

export const renderSign: ItemRenderer<ISignClass> = (sign, {handleClick, modifiers, query}) => {
    if (!modifiers.matchesPredicate) {
        return null;
    } else {
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                label={sign.sign}
                key={sign.sign}
                onClick={handleClick}
                text={highlightText(sign.sign, query)}
            />
        )
    }
}

export const signSelectProps = {
    itemPredicate: filterSign,
    itemRenderer: renderSign,
    items: SIGN_CLASSES,
}