/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { MenuItem } from "@blueprintjs/core";
import { ItemPredicate, ItemRenderer } from "@blueprintjs/select";
import * as React from "react";
import { taskToString, stateToString } from "./../utils/utils";

export interface IQuery {
    /** ID of query. */
    query_id: number;
    /** ID of tablet fragment. */
    tablet_id: string;
    /** Task of the query. */
    task: number;
    /** Processing status of corresponding task. */
    state: number;
    /** Parameters for task. */
    params: string;
    /** Parameters for task. */
    query_count: number;
    /** Full name of tablet and fragment: 7-f / Bo325 */
    tablet_fullname: string;
}

export interface jsonQuery {
    /** ID of query. */
    query_id: number;
    /** ID of tablet fragment. */
    tablet_id: string;
    /** Task of the query. */
    task: number;
    /** Processing status of corresponding task. */
    state: number;
    /** Parameters for task. */
    params: string;
    /** Parameters for task. */
    query_count: number;
    /** Name of tablet.*/
    tablet_name: string;
    /** Name of fragment.*/
    tablet_fragment_name: string;
}

/*
function task_to_string(task: number) {
    if (task === 1)
        return "Annotate";

    if (task === 2)
        return "QExample";

    if (task === 3)
        return "QExpress";
    
    if (task === 4)
        return "Classify";
}

function state_to_string(task: number) {
    if (task === 1)
        return "Queued";

    if (task === 2)
        return "Processing";

    if (task === 3)
        return "Done";
}
*/

export const renderQuery: ItemRenderer<IQuery> = (query_, { handleClick, modifiers, query }) => {
    if (!modifiers.matchesPredicate) {
        return null;
    }

    //const text = `${query_.query_id + " " + task_to_string(query_.task) + " " + state_to_string(query_.state) + " " + query_.tablet_id}`;
    const text = `${query_.query_id + " " + taskToString(query_.task) + " " + stateToString(query_.state) + " " + query_.tablet_fullname}`;
    
    return (
        <MenuItem
            active={modifiers.active}
            disabled={modifiers.disabled}
            label={query_.query_count.toString()}
            key={query_.query_id}
            icon={'layers'}
            onClick={handleClick}
            text={highlightText(text, query)}
        />
    );
};

export const renderCreateQueryOption = (
    query: string,
    active: boolean,
    handleClick: React.MouseEventHandler<HTMLElement>,
) => (
        <MenuItem
            icon="add"
            text={`Create "${query}"`}
            active={active}
            onClick={handleClick}
            shouldDismissPopover={false}
        />
    );

export const filterQuery: ItemPredicate<IQuery> = (query, tablet, _index, exactMatch) => {
    const normalizedName = tablet.tablet_fullname.toLowerCase();
    const normalizedQuery = query.toLowerCase();

    const idMatch = (tablet.query_id.toString() == query)
    if (exactMatch || idMatch) {
        return true;
    } else {
        return `${normalizedName}`.indexOf(normalizedQuery) >= 0;
    }
};

function highlightText(text: string, query: string) {
    let lastIndex = 0;
    const words = query
        .split(/\s+/)
        .filter(word => word.length > 0)
        .map(escapeRegExpChars);
    if (words.length === 0) {
        return [text];
    }
    const regexp = new RegExp(words.join("|"), "gi");
    const tokens: React.ReactNode[] = [];
    while (true) {
        const match = regexp.exec(text);
        if (!match) {
            break;
        }
        const length = match[0].length;
        const before = text.slice(lastIndex, regexp.lastIndex - length);
        if (before.length > 0) {
            tokens.push(before);
        }
        lastIndex = regexp.lastIndex;
        tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
    }
    const rest = text.slice(lastIndex);
    if (rest.length > 0) {
        tokens.push(rest);
    }
    return tokens;
}

function escapeRegExpChars(text: string) {
    // TODO: Still same lines?
    //return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
    return text.replace(/([.*+?^=!:${}()|[\]/\\])/g, "\\$1");
}

export const querySelectProps = {
    itemPredicate: filterQuery,
    itemRenderer: renderQuery,
    items: new Array<IQuery>(),
};

export function createQuery(query_id: number, tablet_id: string, task: number, state: number, params: string, query_count: number, tablet_fullname: string): IQuery {
    return {
        query_id,
        tablet_id,
        task,
        state,
        params,
        query_count,
        tablet_fullname,
    };
}

export function areQueriesEqual(queryA: IQuery, queryB: IQuery) {
    // Compare only the names (ignoring case) just for simplicity.
    return queryA.tablet_fullname === queryB.tablet_fullname;
}

export function arrayContainsQuery(queries: IQuery[], queryToFind: IQuery | undefined): boolean {
    if (queryToFind === undefined) return false;
    return queries.some((query: IQuery) => query.tablet_fullname === queryToFind.tablet_fullname);
}

export function addQueryToArray(queries: IQuery[], queryToAdd: IQuery) {
    return [...queries, queryToAdd];
}

export function deleteQueryFromArray(queries: IQuery[], queryToDelete: IQuery) {
    return queries.filter(query => query !== queryToDelete);
}

export function doesQueryEqualQuery(tablet: IQuery, query: string) {
    return tablet.tablet_fullname.toLowerCase() === query.toLowerCase();
}

export function maybeAddCreatedQueryToArrays(
    items: IQuery[],
    createdItems: IQuery[],
    query: IQuery,
): { createdItems: IQuery[]; items: IQuery[] } {
    const isNewlyCreatedItem = !arrayContainsQuery(items, query);
    return {
        createdItems: isNewlyCreatedItem ? addQueryToArray(createdItems, query) : createdItems,
        // Add a created tablet to `items` so that the tablet can be deselected.
        items: isNewlyCreatedItem ? addQueryToArray(items, query) : items,
    };
}

export function maybeDeleteCreatedQueryFromArrays(
    items: IQuery[],
    createdItems: IQuery[],
    query: IQuery | undefined,
): { createdItems: IQuery[]; items: IQuery[] } {
    const wasItemCreatedByUser = arrayContainsQuery(createdItems, query);
    if (query === undefined) return { createdItems, items };
    // Delete the item if the user manually created it.
    return {
        createdItems: wasItemCreatedByUser ? deleteQueryFromArray(createdItems, query) : createdItems,
        items: wasItemCreatedByUser ? deleteQueryFromArray(items, query) : items,
    };
}
