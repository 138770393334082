import { clientConfig } from './../../config.js';

const tasks = {
    ANNOTATE: 1,
    QUERYBYEXAMPLE: 2,
    QUERYBYEXPRESSION: 3,
    CLASSIFY: 4,
}

export function taskToNum(task: string): number {
    var taskNumber = -1;
    if (task === "annotate") {
      taskNumber = 1;
    } else if (task === "querybyexample") {
      taskNumber = 2;
    } else if (task === "querybyexpression") {
      taskNumber = 3;
    } else if (task === "classify") {
      taskNumber = 4;
    }
    return taskNumber;
}

export function taskToString(task: number): string {
    var taskString = "";
    if (task === 1) {
        taskString = "Annotate";
    } else if (task === 2) {
        taskString = "QExample";
    } else if (task === 3) {
        taskString = "QExpress";    
    } else if (task === 4) {
        taskString = "Classify";
    }
    return taskString;
}

export function stateToString(task: number): string {
    var stateString = "";
    if (task === 1) {
        stateString = "Queued";
    } else if (task === 2) {
        stateString = "Processing";
    } else if (task === 3) {
        return "Done";
    }
    return stateString;
}

export function customFetch(input: RequestInfo, init?: RequestInit | undefined): Promise<Response> {
    return fetch(clientConfig.webApiProtocol + clientConfig.webApiUrl + ":" + clientConfig.webApiPort + "/api/refreshToken", {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/json',
            'Keep-Alive': 'timeout=180, max=5'
        }),
        body: JSON.stringify({
            "username": localStorage.getItem('username'),
            "refreshToken": localStorage.getItem('refreshToken')
        })
    }).then(response => {
        return response.json();
    }).then(data => {
        if (data.success === true) {
            localStorage.setItem('token', data.token);
        }
    }).then(() => {
        if (init !== undefined) {
            let token = localStorage.getItem('token');
            if (init.headers !== undefined) {
                if (token !== null) {
                    let requestHeaders: any = JSON.parse(JSON.stringify({'Authorization': token}));
                    let oldHeaders = JSON.parse(JSON.stringify(init.headers));
                    for (var key in oldHeaders) {
                        if (oldHeaders.hasOwnProperty(key) && key !== 'Authorization') {
                            //console.log(key + " -> " + oldHeaders[key]);
                            requestHeaders[key] = oldHeaders[key];
                        }
                    }
                    requestHeaders['Keep-Alive'] = 'timeout=180, max=5';                    
                    init.headers = requestHeaders;
                }                
            }
            return fetch(input, init);
        }
        return fetch(input, init);
    });
}

export function customFetchLoginStatus(): Promise<boolean> {
    return fetch(clientConfig.webApiProtocol + clientConfig.webApiUrl + ":" + clientConfig.webApiPort + "/api/refreshToken", {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/json',
            'Keep-Alive': 'timeout=180, max=5'
        }),
        body: JSON.stringify({
            "username": localStorage.getItem('username'),
            "refreshToken": localStorage.getItem('refreshToken')
        })
    }).then(response => {
        return response.json();
    }).then(data => {
        if (data.success === true) {
            localStorage.setItem('token', data.token);
            return true;
        }else {
            return false;
        }
    });
}

export const colormap = require('colormap')({
    colormap: 'warm',
    nshades: 100,
    format: 'rgbaString',
    alpha: 0.9
})

export function color(score: number): string {
    score = score > 99 ? 99 : score;
    score = score < 0 ? 0 : score;
    return colormap[Math.floor(score)]
}

const util = {customFetch, customFetchLoginStatus, stateToString, taskToString, taskToNum, tasks, color}

export default util